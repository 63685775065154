import React from "react";
import styled from "styled-components";
import FacebookIcon from "../socials/facebook";
import InstagramsIcon from "../socials/instagrams";
import PinIcon from "../socials/pin";
import YoutubeIcon from "../socials/youtube";

type TSiteSocial = {
  link?: string,
  type: string
}


const BaseSocials = ({ socials } :{ socials: TSiteSocial[]} ) => {
  return (
    <Socials className="socials">
      <ul>
        { socials && socials.map((item: TSiteSocial, key:number)=> {
          return (
            <li className="social" key={key}>
                 <a href={item.link} title={`StudioKast ${item.type}`} target="_blank" rel="noopener noreferrer" className="transition-all">
                  { item.type === 'map' && <PinIcon /> }
                  { item.type === 'facebook' && <FacebookIcon /> }
                  { item.type === 'youtube' && <YoutubeIcon /> }
                  { item.type === 'instagram' && <InstagramsIcon /> }
                </a>
            </li>
          )
        })}

      </ul>
    </Socials>
  )
}

export default BaseSocials

const Socials = styled.div`
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;

    li {
      display: block;
      margin-right: 5px;

      a {
        display: flex;
        background-color: transparent;
        width: 44px;
        height: 44px;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        overflow: hidden;
        box-sizing: border-box;

        .svg {
          &-stroke {
            stroke: ${({theme}) => theme.colors.blackColor};
            fill: ${({theme}) => theme.colors.blackColor};
          }

          &-fill {
            fill: ${({theme}) => theme.colors.blackColor};
          }
        }

        &:hover,
        &:active,
        &:focus {
          background-color: ${({theme}) => theme.colors.blackColor};

          .svg {
            &-stroke {
              stroke: #fff;
              fill: #fff;
            }

            &-fill {
              fill: #fff;
            }
            
          }
        }
      }
    }
  }
`

