import React from "react"
import BaseAddress from "../base/Address"
import BaseSocials from "../base/Socials"
import Link from "next/link"
import styled from "styled-components"

const Infos = ({ information, socials}: { information: any, socials: any }) => {
  return (
    <InfosComp>
        { information && <div className="infos__top">
          <BaseAddress 
              name={information.name ? information.name : ''}
              address={information.address_1 ? information.address_1 : ''}
              address2={information.address_2 ? information.address_2 : ''}
              phone={information.phone ? information.phone : ''}
              email={information.mail ? information.mail : ''}
          /> 
        </div> }
        <div className="infos__center">
          
          {socials && <BaseSocials 
              socials={socials}
          /> }

          <div className="infos__links">
            <Link href={'/impressum'}>
              <a title="Impressum">Impressum</a>
            </Link>
            {/* <Link href={'/agb'}>
              <a title="AGB">AGB&apos;s</a>
            </Link> */}
          </div>
        </div>
    </InfosComp>
  )
}

export default Infos

const InfosComp = styled.div`
  .infos {
    &__center {
      display: flex;
    }

    &__links {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      > a {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .socials {
    margin-top: 50px;
    transform: translate(-10px,0);
  }


  @media screen and (min-width: ${({theme}) => theme.breakPoints.desktop}) {
    display: flex;
    justify-content: flex-end;

    .socials {
      margin-top: 0;
      transform: translate(0,0);
    }

    .address {
      ul {
        display: flex;
        justify-content: space-around;

        li {
          margin-right: 5px;
          margin-bottom: 0;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .infos {
      &__links {
        flex-direction: row;
        align-items: flex-start;
        padding-top: 12px;

        > a {
          margin-right: 10px;
          margin-bottom: 0;
        }
      }

      &__top {
        padding-top: 12px;
      }
    }

  }


  @media screen and (min-width: ${({theme}) => theme.breakPoints.large}) {
    .socials {
      margin: 0 50px 0 100px;
    }
    
    .address {
      ul {
        li {
          margin-right: 30px;
        }
      }
    }
  }
`