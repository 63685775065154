
import React from "react";
import Link from "next/link";
import { useRouter } from 'next/router';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import styled from "styled-components";
import 'swiper/css';

const menuDatas = [
  {
    display: '<span>Das</span> Studio',
    link: '/#content',
    name: 'Das Studio'
  },
  {
    display: '<span>Visuali-</span> sierungen',
    link: '/3d-visuals#content',
    name: 'Visuali-sierungen'
  },
  {
    display: '<span>Produkt-</span> Gestaltung',
    link: '/product-design#content',
    name: 'Produkt-Gestaltung'
  },
  {
    display: '<span>Bildung</span>',
    link: '/we-learn#content',
    name: 'Bildung'
  },
]

const Menus = ({ swipe, variant, scrollTop, activeSlide }: { swipe?: boolean, variant?: string, scrollTop: boolean, activeSlide: number }) => {
  const router = useRouter();
  return (
    <MenusWrap className={`menus-wrap ${variant ? variant : ''} ${swipe ? 'has-swipe' : ''}`}>

      <div className="menu-wrap grid">
        { menuDatas && menuDatas.map(( menu: any, key: number) => {
          return (
            <div key={key} className="menu-item ">
              <Link href={menu.link} scroll={scrollTop ? true : false}>
                <a 
                  dangerouslySetInnerHTML={{__html: menu.display }} 
                  title={menu.name}
                  className={`transition-all ${router.pathname == menu.link ? "active" : "" }`}
                />
              </Link>
            </div>
          )
        })}
      </div>

      { swipe && <div className="menu-wrap can-swipe">
          <Swiper
            spaceBetween={20}
            slidesPerView={"auto"}
            modules={[Navigation]}
            initialSlide={activeSlide}
            navigation={{
              nextEl: '.can-swipe-footer--next',
              prevEl: '.can-swipe-footer--prev',
            }}

            breakpoints={{
              // when window width is >= 480px
              768: {
                slidesPerView: 2.2,
                spaceBetween: 30
              }
            }}

          >
              { menuDatas && menuDatas.map(( menu: any, key: number) => {
                return (
                  <SwiperSlide key={key} className="menu-item ">
                    <Link href={menu.link}>
                      <a 
                        dangerouslySetInnerHTML={{__html: menu.display }} 
                        title={menu.name}
                        className={`transition-all ${router.pathname == menu.link ? "active" : "" }`}
                      />
                    </Link>
                  </SwiperSlide>
                )
            })}

          </Swiper>

          <div className="can-swipe-footer">
            <span className="can-swipe-footer--prev"> 
                <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.5625 9.38896L0.5 5.32646L0.5 4.18896L4.5625 0.126465L5.71625 1.26396L3.05125 3.94521L12.1025 3.94521V5.57022L3.05125 5.57022L5.7325 8.25146L4.5625 9.38896Z" fill="black"/>
                </svg>
            </span>
            <span className="can-swipe-footer--next">
                <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.27539 0.613966L12.3379 4.67647L12.3379 5.81397L8.27539 9.87647L7.12164 8.73897L9.78664 6.05772L0.735389 6.05772L0.735389 4.43272L9.78664 4.43271L7.10539 1.75147L8.27539 0.613966Z" fill="black"/>
                </svg>
            </span>
          </div>
      </div> }

    </MenusWrap>
  )
}

export default Menus

const MenusWrap = styled.div`
  min-height: 40px;
  width: 100%;
  box-sizing: border-box;

  .menu {
    &-item {
      margin-bottom: 15px;

      a {
        font-size: 40px;
        text-align: left;
        text-transform: uppercase;
        text-underline-offset: 4px;
        display: block;
      
        span {
          display: block;
        }

        &.active {
          text-decoration: underline;
        }
      }
    }
  }

  &.has-swipe {

    .menu-wrap {
      &.grid {
        display: none;
      }
    }
  }

  .swiper {
    width: 100%;
    height: 100%;

    &-slide {
      width: auto;
      padding-right: 10vw;
    }
  }

  .can-swipe-footer {
    text-align: right;
    margin-right: -13px;

    span {
      display: inline-flex;
      width: 40px;
      height: 40px;
      transform: all 600ms;

      svg {
        margin: auto;
      }
    }

    .swiper-button-disabled {
      display: none;
    }
  }

  @media screen and (min-width: ${({theme}) => theme.breakPoints.desktop}) {
    .menu-wrap {
      &.grid {
        display: flex;
        box-sizing: border-box;
        overflow: hidden;
        width: 100%;
        justify-content: space-between;

        .menu-item {
          flex-shrink: 1;
          margin-bottom: 0;
          

          &:last-child {
            a {
              text-align: left;
            }
            
          }
        }
      }
    }

    &.has-swipe {

      .menu-wrap {
        &.grid {
          display: flex;
        }

        &.can-swipe {
          display: none;
        }
      }
    }

  }


  @media screen and (min-width: ${({theme}) => theme.breakPoints.wide }) {
    .menu {
      &-item {
        a {
          font-size: 50px;
          font-size: 3.472vw
        }
      }
    }
  }

`

