import React from 'react'
import styled from 'styled-components'

const ContactButton = () => {

  const contactHandle = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    window.location.href = `mailto:${process.env.NEXT_EMAIL ? process.env.NEXT_EMAIL : 'hi@studiokast.ch'}`;
  }

  return (
    <ButtonComp
      type='button' 
      className='contactBtn weight-700'
      onClick={(e) => contactHandle(e)}
    >
      <span className='transition-all'>say</span>
      <span className='transition-all'>hi!</span>
    </ButtonComp>
  )
}

export default ContactButton

const ButtonComp = styled.button`
  border: 0;
  background-color: transparent;
  width: 36px;
  font-size: 14px;
  padding: 0;
  cursor: pointer;

  span {
    display: block;
    width: 100%;
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
  
    &:first-child {
      padding: 20px 0 10px;
      background-color: #000;
      color: #fff;
      border-radius: 40px 40px 0 0;
    }

    &:last-child {
      padding: 10px 0 20px;
      border-radius: 0 0 40px 40px;
    }
  }

  &:hover,
  &:active,
  &:focus {
    span {
      &:first-child {
        background-color: #fff;
        color: #000;
      }
      &:last-child {
       
        background-color: #000;
        color: #fff;
      }
    }
  }
`