import React, { useEffect } from "react";
import styled from "styled-components";
import { motion, useMotionValue } from "framer-motion";

const CustomCursor = () => {
  const cursorX = useMotionValue(-100)
  const cursorY = useMotionValue(-100)

  // const springConfig = { damping: 10, stiffness: 10 };
  // const cursorXSpring = useSpring(cursorX, springConfig);
  // const cursorYSpring = useSpring(cursorY, springConfig);


  useEffect(() => {
    const moveCursor = (e:any) => {
      cursorX.set(e.clientX - 16)
      cursorY.set(e.clientY - 16)

      
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('mousemove', moveCursor)

      return () => {
        window.removeEventListener('mousemove', moveCursor)
      }
    }

  },[cursorX, cursorY])
  return (
    <><CustomCursorWrap>
      <motion.div 
        className="cursor"
        style={{
          translateX: cursorX,
          translateY: cursorY,
        }}
      ></motion.div>
    </CustomCursorWrap>
    </>
  )
}

export default CustomCursor;

const CustomCursorWrap = styled.div`
  .cursor {
    position: fixed;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border-radius: 16px;
    mix-blend-mode: difference;
    background-color: #fff;
    z-index: 9999;
    pointer-events: none;
    display: none;
  }

  @media screen and (min-width: ${({theme}) => theme.breakPoints.desktop}) {
    .cursor {
      display: block;
    }
  }
`