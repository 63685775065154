import React from "react"

const PinIcon = () => {
  return (
    <svg width="19" height="26" viewBox="0 0 19 26" fill="none" xmlns="http://www.w3.org/2000/svg" className="svg-fill transition-all">
      <path d="M9.71658 25.0559C9.92725 25.0559 10.129 24.9519 10.2545 24.7722C11.2765 23.3303 12.1729 22.087 12.9618 20.9902C17.5115 14.6648 18.4573 13.3505 18.4573 10.0696C18.4573 4.98749 14.5352 0.850891 9.71658 0.850891C4.89799 0.850891 0.975887 4.98749 0.975887 10.0696C0.975887 13.3458 1.92616 14.6648 6.48477 21.0091C7.26919 22.1012 8.16119 23.3398 9.17869 24.7722C9.3042 24.9519 9.50591 25.0559 9.71658 25.0559ZM9.71658 2.26915C13.7956 2.26915 17.1126 5.76753 17.1126 10.0696C17.1126 12.873 16.3147 13.984 11.8905 20.1345C11.2406 21.0375 10.5234 22.0397 9.71658 23.1649C8.91871 22.0444 8.20153 21.0469 7.55606 20.1487C3.11848 13.9793 2.32061 12.8683 2.32061 10.0696C2.32061 5.76753 5.63759 2.26915 9.71658 2.26915Z"/>
      <path d="M9.71657 15.0336C12.3119 15.0336 14.4231 12.8069 14.4231 10.0697C14.4231 7.33244 12.3119 5.10577 9.71657 5.10577C7.12125 5.10577 5.01004 7.33244 5.01004 10.0697C5.01004 12.8069 7.12125 15.0336 9.71657 15.0336ZM9.71657 6.52404C11.5723 6.52404 13.0784 8.11249 13.0784 10.0697C13.0784 12.0269 11.5723 13.6153 9.71657 13.6153C7.86085 13.6153 6.35476 12.0269 6.35476 10.0697C6.35476 8.11249 7.86085 6.52404 9.71657 6.52404Z" />
    </svg>
  )
}

export default PinIcon