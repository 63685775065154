import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ContactButton from "../base/ContactButton";
import Menus from "../base/Menus";
import Infos from "../infos/Index";
import client from "../../lib/graphql"
import { GET_SITE_INFO } from '../../graphql/query'

type TSiteInfos = {
  address_1?: string,
  address_2?: string,
  mail?: string,
  name?: string
  phone?: string
}

type TSiteSocial = {
  link?: string,
  type: string
}

const BaseMenu = () => {
  
  const [siteInfos, setSiteInfos] = useState<TSiteInfos|null> (null)
  const [siteSocials, setSiteSocials] = useState<TSiteSocial[]|[]> ([])

  useEffect(() => {

    const fetchSiteInfo = async () => {
      const { data } = await client.query({
        query: GET_SITE_INFO,
      });
      
      if ( data) {
        const { siteSetting: { data: { attributes : { site_info, socials }}}} = data
        setSiteInfos(Object.assign({}, site_info ));
        setSiteSocials(socials)
      }
    }

    fetchSiteInfo()
    .catch(console.error);
    
  },[])

  return (
    <BaseMenuWrap>
      <div className="scrollable">
        <div className="menus">
          <Menus swipe={false} scrollTop={false} activeSlide={0} />
        </div>
        <div className="infos">
          <ContactButton />
          <Infos information={siteInfos} socials={siteSocials} />
        </div>
      </div>
    </BaseMenuWrap>
  )
}

export default BaseMenu

const BaseMenuWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  z-index: 999;
  overflow: hidden;
  padding: 100px 20px 20px;

  .contactBtn {
    margin-bottom: 40px;
  }
  
  .menus {
    margin-bottom: 60px;
  }

  .scrollable {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 40px;
  }

  @media screen and (min-width: ${({theme}) => theme.breakPoints.desktop}) {
    padding-top: 20px;

    .scrollable {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      padding-bottom: 0;
    }
    .menus {
      margin-bottom: 0;
    }

    .infos {
      position: absolute;
      bottom: 0;
      width: 100%;

      .contactBtn {
        margin-bottom: 0;
        position: absolute;
        bottom: 20px;
      }
    }
  }
`