import React from "react";
import styled from "styled-components";

type Props = {
  name?: string,
  address?: string,
  address2?: string,
  phone?: string,
  email?: string
}

const BaseAddress = ({ name = '', address = '', address2, phone = '', email = '' }:Props) => {

  const phoneHandle = (e: any, phone: string) => {
    e.preventDefault();
    let _phone = phone.replace(/\s/g, '');
    window.location.href=`tel:${_phone}`
  }

  const mailHandle = (e: any, _mail: string) => {
    e.preventDefault();
    let mail = _mail.replace(/\s/g, '');
    window.location.href=`mailto:${mail}`
  }

  const scrollTop = () => {
    if (typeof window !== 'undefined') {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  return (
    <Address className="address">
      <ul>
      { name && <li className="address__item" onClick={() => scrollTop()} style={{cursor: 'pointer'}}>{ name }</li> }
      { address && <li className="address_item">{ address }</li> }
      { address2 &&  <li className="address__item">{ address2 }</li>}
      { phone && <li className="address__item phone"><span>T</span> <a href="#" onClick={(e)=> phoneHandle(e, phone)} target="_blank">{ phone }</a></li>}
      { email && <li className="address__item"><a href="#" onClick={(e) => mailHandle(e, email)} title="" target="_blank">{email}</a></li>}
      </ul>
    </Address>
  )
}

export default BaseAddress

const Address = styled.div`
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      font-size: 14px;
      line-height: 1.3;
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        line-height: 1.3;
      }

      &.phone {
        cursor: pointer;
        span {
          display: none;
        }
      }
    }
  }

  @media screen and (min-width: ${({theme}) => theme.breakPoints.desktop}) {
    ul {
      li {
        &.phone {
          span { display: inline-block }
        }
      }
    }
  }
`

